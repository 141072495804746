import React from 'react';
import {
  BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import Home from './Home';
import SonderborgAD from './sso-logout/SonderborgAD';

function App() {
  return (
      <Router>
        <Switch>
          <Route path="/sonderborg-ad">
            <SonderborgAD/>
          </Route>
          <Route path="/">
            <Home/>
          </Route>
        </Switch>
      </Router>

  );
}

export default App;
