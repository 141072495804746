import React from 'react';
import * as $ from 'jquery'

export default class SonderborgAD extends React.Component {
  async componentDidMount() {
    await this.logoutOfSso();
  }

  logoutOfSso() {
    $.ajax({
      username: 'wrong',
      password: 'password',
      url: 'https://sso.sonderborg.dk/adfs/ls/wia?' + this.getGuid(),
      type: 'GET',
      beforeSend: function(xhr) {
        xhr.setRequestHeader("Authorization", "Basic AAAAAAAAAAAAAAAAAAA=");
      },
      error: function(err) {
        console.log("Bad auth sent to server");
        window.location.href = 'http://sonderborg.pilot.justklikkit.com';
      }
    });
  }

  getGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  render(){
    return <h1>You will be redirected in a sec...</h1>
  }
}
